import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import LayoutBasic from 'layouts/LayoutBasic'
import Seo from 'components/atoms/Seo'
import BannerSimple from 'components/organisms/Banner/BannerSimple'
import BlogListing from 'components/organisms/BlogListing'

import useBreakpoint from 'hooks/useBreakpoint'

const PageBlog: React.FC<PageProps<Queries.PageBlogQuery>> = ({ data }) => {
  const PAGE = data?.wpPage?.PageBlog!
  const POSTS = data.allWpPost?.edges!
  const SEO = data?.wpPage?.seo!

  if (!PAGE || !POSTS || !SEO) throw new Error("CMS data didn't load properly")

  const { lg } = useBreakpoint()

  const dataBlogListingPosts = POSTS.map((post) => ({
    title: post?.node?.title!,
    uri: post?.node?.uri!,
    excerpt: post?.node?.excerpt!,
    thumbnail:
      post?.node?.featuredImage?.node?.localFile?.childImageSharp
        ?.gatsbyImageData!,
  }))

  return (
    <LayoutBasic>
      <Seo
        title={SEO?.title!}
        description={SEO?.metaDesc!}
        ogTitle={SEO?.opengraphTitle!}
        ogDescription={SEO?.opengraphDescription!}
        ogImage={SEO?.opengraphImage?.sourceUrl!}
        twitterTitle={SEO?.twitterTitle}
        twitterDescription={SEO?.twitterDescription}
        twitterImage={SEO?.twitterImage?.sourceUrl}
      />
      <BannerSimple
        title={PAGE?.bannerTitle!}
        background={
          lg
            ? PAGE?.bannerBg?.localFile?.childImageSharp?.gatsbyImageData!
            : PAGE?.bannerBgMobile?.localFile?.childImageSharp?.gatsbyImageData!
        }
        high
      />
      <BlogListing posts={dataBlogListingPosts} />
    </LayoutBasic>
  )
}

export default PageBlog

export const query = graphql`
  query PageBlog {
    wpPage(slug: { regex: "/strefa-wiedzy/" }) {
      PageBlog {
        bannerTitle
        bannerBg {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
          }
        }
        bannerBgMobile {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 700)
            }
          }
        }
      }
      seo {
        title
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
    }
    allWpPost {
      edges {
        node {
          title
          uri
          excerpt
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
          }
        }
      }
    }
  }
`
