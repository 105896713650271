import { Link } from 'gatsby'
import styled from 'styled-components'

import { LazyImage } from 'components/atoms/Image'
import { ButtonPrimary } from 'components/atoms/Button'

import media from 'styles/media'

export const Wrapper = styled.section`
  margin: 96px 0;

  ${media.lg.max} {
    margin: 64px 0;
  }
`

export const Inner = styled.div``

export const Content = styled.div``

export const PostContent = styled.div`
  margin: 0;
  padding: 0;
  padding: 0 16px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${media.sm.min} {
    padding: 0 32px;
  }

  ${media.xxl.min} {
    padding: 0 64px;
  }

  ${media.lg.max} {
    width: 100%;
    max-width: 100%;
    padding: 0 !important;
  }
`

export const PostThumbnail = styled(LazyImage)`
  position: absolute;
  right: 0;
  top: 0;
  width: 50vw;
  max-width: 960px;
  height: 100%;

  ${media.lg.max} {
    width: 100%;
    max-width: 100%;
  }
`

export const Post = styled.article`
  display: flex;
  align-items: stretch;

  ${media.lg.max} {
    flex-direction: column-reverse;
    margin: 0 0 72px 0;
    &:last-child {
      margin: 0;
    }
  }

  ${media.lg.min} {
    &:nth-child(2n) {
      flex-direction: row-reverse;

      ${PostContent} {
        padding-left: 0;
      }

      ${PostThumbnail} {
        right: unset;
        left: 0;
      }
    }
  }
`

export const PostTitle = styled.h2`
  font-size: 25px;
  line-height: 31px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};

  ${media.lg.max} {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
  }
`

export const PostSubtitle = styled.p`
  margin: 0 0 24px 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary50};
`

export const PostExcerpt = styled.div`
  margin: 12px 0 0 0;

  p {
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.white};
  }
`

export const PostReadMore = styled(Link)`
  ${ButtonPrimary};

  margin: 32px 0 0 0;

  ${media.lg.max} {
    margin: 24px 0 0 0;
  }
`

export const PostThumbnailWrapper = styled.div`
  position: relative;
  width: 50%;
  height: 620px;

  ${media.lg.max} {
    margin: 48px 0 0 0;
    height: 80vw;
    width: 100%;
  }
`
