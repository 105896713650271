import React from 'react'

import {
  Wrapper,
  Inner,
  Content,
  Post,
  PostThumbnailWrapper,
  PostThumbnail,
  PostContent,
  PostSubtitle,
  PostTitle,
  PostExcerpt,
  PostReadMore,
} from 'components/organisms/BlogListing/BlogListing.style'
import Container from 'components/atoms/Container'

import generateKey from 'utils/generateKey'

import { IGatsbyImageData } from 'gatsby-plugin-image'

type Props = {
  posts: Array<{
    title: string
    uri: string
    excerpt: string
    thumbnail: IGatsbyImageData
  }>
}

function trimExcerpt(content: string, length: number) {
  return content.split(' ').slice(0, length).join(' ')
}

const BlogListing: React.FC<Props> = ({ posts }) => {
  return (
    <Wrapper>
      <Container>
        <Inner>
          <Content>
            {posts &&
              posts.map((post, postIndex) => (
                <Post key={generateKey(postIndex)}>
                  <PostThumbnailWrapper>
                    <PostThumbnail
                      src={post.thumbnail}
                      objectFit="cover"
                      alt=""
                    />
                  </PostThumbnailWrapper>
                  <PostContent>
                    {postIndex === 0 && (
                      <PostSubtitle>Najnowszy wpis</PostSubtitle>
                    )}
                    <PostTitle>{post.title}</PostTitle>
                    <PostExcerpt
                      dangerouslySetInnerHTML={{
                        __html: trimExcerpt(post.excerpt, 32),
                      }}
                    />
                    <PostReadMore to={post.uri} small>
                      Czytaj więcej
                    </PostReadMore>
                  </PostContent>
                </Post>
              ))}
          </Content>
        </Inner>
      </Container>
    </Wrapper>
  )
}

export default BlogListing
